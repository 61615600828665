import React from "react";
import Lottie from "react-lottie";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import consultation from "../assets/consultationIcon.svg";
import vision from "../assets/vision.svg";
import mockup from "../assets/mockupIcon.svg";
import review from "../assets/reviewIcon.svg";
import design from "../assets/designIcon.svg";
import build from "../assets/buildIcon.svg";
import launch from "../assets/launchIcon.svg";
import maintain from "../assets/maintainIcon.svg";
import iterate from "../assets/iterateIcon.svg";

import technologyAnimation from "../animations/technologyAnimation/data.json";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import integrationAnimation from "../animations/integrationAnimation/data.json";

import CallToAction from "./ui/CallToAction";

const useStyles = makeStyles()((theme) => {
  return {
    heading: {
      maxWidth: "40em",
    },
    arrowContainer: {
      marginTop: "0.5em!important",
    },
    rowContainer: {
      paddingLeft: "5em",
      paddingRight: "5em",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
      },
    },
    paragraphContainer: {
      maxWidth: "30em!important",
    },
  };
});

const Revolution = (props) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("mh"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("cs"));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: technologyAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.rowContainer} style={{ marginTop: "2em" }}>
        <Typography align={matchesMd ? "center" : undefined} variant="h2" style={{ fontFamily: "pacifico" }}>
          The Revolution
        </Typography>
      </Grid>
      <Grid item container direction={matchesMd ? "column" : "row"} alignItems="center" className={classes.rowContainer} style={{ marginTop: "5em" }}>
        <Grid item lg>
          <img
            src={vision}
            alt="mountain through binoculars"
            style={{ maxWidth: matchesSM ? matchesXS? "250px": "300px" : "40em", marginRight: matchesMd ? 0 : "5em", marginBottom: matchesMd ? "5em" : 0 }}
          />
        </Grid>
        <Grid item container direction="column" lg style={{ maxWidth: "40em" }}>
          <Grid item>
            <Typography align={matchesMd ? "center" : "right"} variant="h4" gutterBottom>
              Vision
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : "right"} gutterBottom paragraph>
              The rise of computers, and subsequently the Internet, has completely altered every aspect of human life. This has increased our comfort,
              broadened our connections, and reshaped how we view the world.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : "right"} gutterBottom paragraph>
              What once was confined to huge rooms and teams of engineers now resides in every single one of our hands. Harnessing this unlimited
              potential by using it to solve problems and better lives is at the heart of everything we do.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : "right"} gutterBottom paragraph>
              We want to help businesses capitalize on the latest and greatest technology. The best way to predict the future is to be the one
              building it, and we want to help guide the world into this next chapter of technological expansion, exploration, and innovation.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : "right"} gutterBottom paragraph>
              By holding ourselves to rigorous standards and pristine quality, we can ensure you have the absolute best tools necessary to thrive in
              this new frontier.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : "right"} gutterBottom paragraph>
              We see a future where every individual has personalized software custom tailored to their lifestyle, culture, and interests, helping
              them overcome life's obstacles. Each project is a step towards that goal.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/**technology second block */}
      <Grid
        item
        container
        direction={matchesMd ? "column" : "row"}
        alignItems="center"
        className={classes.rowContainer}
        style={{ marginTop: "10em", marginBottom: "10em" }}
      >
        <Grid item container direction="column" lg style={{ maxWidth: "40em" }}>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom>
              Technology
            </Typography>
          </Grid>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              In 2013, Facebook invented a new way of building websites. This new system, React.js, completely revolutionizes the process and practice
              of website development.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              Instead of chaining together long individual pages, like traditional websites, React websites are built with little chunks of code
              called components. These components are faster, easier to maintain, and are easily reused and customized, each serving a singular
              purpose.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              Two years later they shocked the world by releasing a similar system, React Native, for producing 10S and Android apps. Instead of
              having to master two completely separate development platforms, you can leverage the knowledge you already possessed from building
              websites and reapply it directly! This was a huge leap forward.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              This technology is now being used by companies like AirBnB, Microsoft, Netflix, Pinterest, Skype, Tesla, UberEats, and when Facebook
              purchased Instagram large portions of it were even rebuilt using React.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              Developers have since built on top of these systems by automating project setup and deployment, allowing creators to focus as much as
              possible on their work itself.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              These technical advancements translate into savings by significantly reducing the workload and streamlining the workflow for developing
              new pieces of software, while also lowering the barrier to entry for mobile app development.
            </Typography>
            <Typography align={matchesMd ? "center" : undefined} variant="body2" gutterBottom paragraph>
              This puts personalization in your pocket - faster, better, and more affordable than ever before.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent={matchesMd ? "center" : "flex-end"} lg>
          <Lottie options={defaultOptions} style={{ maxWidth: "40em", margin: 0 }} />
        </Grid>
      </Grid>
      {/**end technology second block */}

      {/** Process third block */}
      <Grid item container className={classes.rowContainer} direction="row" justifyContent="center">
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Process
          </Typography>
        </Grid>
      </Grid>
      {/** end Process third block */}
      {/**consultaion block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#B3B3B3", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }} align={matchesMd ? "center" : undefined}>
              Consultation
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" style={{ color: "#fff", maxWidth: "20em" }} paragraph align={matchesMd ? "center" : undefined}>
              Our process begins the moment you realize you need a piece of technology for your business. Whether you already have an idea for where
              to start and what to do, or if you just know you want to step things. up, our initial consultation will help you examine your business
              holistically to find the best solutions,
            </Typography>
            <Typography variant="body2" style={{ color: "#fff", maxWidth: "20em" }} paragraph align={matchesMd ? "center" : undefined}>
              Detailed notes will be taken on your requirements and constraints, while taking care to identify other potential areas for
              consideration.
            </Typography>
            <Typography variant="body2" style={{ color: "#fff", maxWidth: "20em" }} paragraph align={matchesMd ? "center" : undefined}>
              detection and natural language processing allow computers to do things previously unimaginable, and our expertise and intuition will
              help usher you into this new future of possibilities,
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={consultation} alt="handshake" width="100%" style={{maxWidth:700}}/>
        </Grid>
      </Grid>

      {/**end consultaion  block */}

      {/**mockup block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#FF7373", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Mockup
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              After we settle on the best path forward and decide on a solution to pursue, details like the cost and timeline witl be finalized.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              That's just a fancy term for a sockup, colors, images, or any other polished design elements, but captures the essential layout
              structure and functionality.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              This helps us understand and refine the solution keelfbef getting distracted by specifics and looks.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={mockup} alt="basic website design outline" style={{maxWidth:1000}} width="100%" />
        </Grid>
      </Grid>

      {/**end mockup  block */}

      {/**review block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#39B54A", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Review
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Before moving any farther we come back to you with our progress. This gives you the freedom to discuss any changes you may want or any
              ideas you may have come up with before any heavy lifting has been done.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              We give you an interactive demonstration of the mockups, thoroughly explaining the thought process that went into each screen and every
              anticipated feature.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Once you're completely satisfied with the vision for our solution we get down to the nitty gritty, fine-aftails of design.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={review} alt="magnifying glass" width="100%"/>
        </Grid>
      </Grid>

      {/**end review  block */}
      {/**design block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#A67C52", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Design
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Using the mockups and notes taken during the consultation as guides, we will start ironing out what the final product will look like.
              This also involves using any brand material like fonts, colors, and logos to extend the experience you're already familiar with.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              No aspect is superfluous, and care will be taken with every decision.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={design} alt="paintbrush leaving stroke of paint" style={{maxWidth:1000}} width="100%" />
        </Grid>
      </Grid>

      {/**end design  block */}
      {/** another review block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#39B54A", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Review
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              A second round of review is essential to our goal of creating exactly what you want, exactly how you want it.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Tits time we'll be going over the finalized designs in another fully interactive demonstration. Again this gives you an opportunity to
              tweak things and make sure we get everything right the first time.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={review} alt="magnifying glass" width="100%" />
        </Grid>
      </Grid>

      {/**end anotherreview  block */}
      {/** build block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? matchesXS ? "row": "column" : "row"}
        style={{ backgroundColor: "#FBB03B", height:matchesXS? undefined: "90em", paddingBottom:matchesXS? 100: undefined }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Build
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Here's where we get down to business.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Engineering begins after your approval on the final designs. We start by scaffolding out the project on a high level, prioritizing some
              areas over others.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Each area is then developed in order of importance until ready to be connected to the next piece.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Typically the backend, behind the scenes operations are completed first. Once all the services are in place we can then create the front
              end, user side of things.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Finishing the application doesn't mean we're done though, because we use extensive testing to guarantee compatibility with all intended
              devices,
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Only after our rigorous examinations will we accept a product as finited, then pushing it through the production pipeline. This prodikes
              an optimized, compressed, consumer version of the code and assets ready for deployment.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={build} alt="building construction site" style={{maxWidth:matchesMd? matchesXS ? 300 :700: 1000}} width="100%" />
        </Grid>
      </Grid>

      {/**end build block */}
      {/** launch block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#C1272D", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Launch
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              The moment we've all been waiting for.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              when construction comes to a close you're the first one to kno We'll give our final demonstration to show off your shiny new software in
              the wild so you know exactly how it will look to your users.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              When you say the word, we press the button and launch your project out to the public. We're there to ensure everything goes to plan so
              you can start reaping the rewards of your technological investment immediately.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={launch} alt="rocket" style={{maxWidth:200 , maxHeight:matchesMd?500:undefined}} width="100%"/>
        </Grid>
      </Grid>

      {/**end launch block */}
      {/** maintain block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#8E45CE", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography align={matchesMd ? "center" : undefined} variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }}>
              Maintain
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Our work does'nt end there.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              After a successful launch we keep in close contact to listen to feedback and hear how the project is being received.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              From there on out we make sure your application is kept up to date and taking advantage of the best features and practices available.
              When new developments arise or new techniques are discovered in future projects, we will implement those advancements in your project as
              part of our routine maintenance.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={maintain} alt="wrench tightening bolts" style={{maxWidth:500}} width="100%"/>
        </Grid>
      </Grid>

      {/**end maintain block */}

      {/** iterate block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        direction={matchesMd ? "column" : "row"}
        style={{ backgroundColor: "#29ABE2", height: "90em" }}
      >
        <Grid item container direction="column" alignItems={matchesMd ? "center" : undefined} lg>
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ color: "#000", marginTop: "5em" }} align={matchesMd ? "center" : undefined}>
              Iterate
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              The cycle repeats whenever you come up with a new idea for extending your current project, or come up with a brand new system entirely.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              By planning for future features and changes we can build and evolve your application over time. As new use cases and customer needs
              develop we can respond with continuous integration of new content.
            </Typography>
            <Typography variant="body2" align={matchesMd ? "center" : undefined} style={{ color: "#fff", maxWidth: "20em" }} paragraph>
              Our iterative process will keep you current and competitive, allowing you to quickly implement changes instead of waiting months for a
              single update.
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg style={{ alignSelf: "center" }}>
          <img src={iterate} alt="falling domiboes" width="100%"/>
        </Grid>
      </Grid>

      {/**end iterate block */}
   
    </Grid>
  );
};

export default Revolution;
