import React from "react";
import Lottie from "react-lottie";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import profile from "../assets/founderawais.jpg";
import yearbook from "../assets/yearbook.svg";
import puppy from "../assets/puppyy.jpg";
import history from "../assets/history.svg";

import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import integrationAnimation from "../animations/integrationAnimation/data.json";

import CallToAction from "./ui/CallToAction";

const useStyles = makeStyles()((theme) => {
  return {
    missionStatement: {
      fontStyle: "italic",
      fontWeight: "300!important",
      fontSize: "1.5rem!important",
      maxWidth: "50em",
      lineHeight: `1.4!important`,
    },
    rowContainer: {
      paddingLeft: "5em",
      paddingRight: "5em",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
      },
    },
    avatar: {
      height: "25em!important",
      width: "25em!important",
      [theme.breakpoints.down("mh")]: {
        height: "20em!important",
        width: "20em!important",
        maxWidth: "300px!important",
        maxHeight: "300px!important",
      },
    },
  };
});

const About = (props) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("mh"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: integrationAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.rowContainer} style={{ marginTop:matchesMd?"1em": "2em" }}>
        <Typography align={matchesMd?"center":undefined} variant="h2"> About Us</Typography>
      </Grid>
      <Grid item container justifyContent="center" className={classes.rowContainer} style={{ marginTop: "3em" }}>
        <Typography variant="h4" align="center" className={classes.missionStatement}>
        Dream X Work is a leading IT development company that offers a wide range of services for web, mobile, and desktop systems. We work on every technology, from front-end to back-end, from native to hybrid, from cloud to local. We have a team of 20 talented and experienced employees, including mobile developers, website developers, content writers, and graphic designers. We are passionate about creating innovative and user-friendly solutions that meet the needs and expectations of our clients. We have worked on many large and small projects, such as e-commerce platforms, social media apps, educational software, and more. We are always ready to take on new challenges and deliver high-quality results. Whether you need a simple website or a complex system, we can help you achieve your goals and dreams. Contact us today and let us work together to make your vision a reality. Dream X Work is your trusted partner in IT development.
        </Typography>
      </Grid>
      {/**history block */}
      <Grid
        item
        container
        className={classes.rowContainer}
        style={{ marginTop: "10em", marginBottom: "10em" }}
        direction={matchesMd ? "column" : "row"}
        alignItems={matchesMd ? "center" : undefined}
        justifyContent="space-around"
      >
        <Grid item>
          <Grid item container direction="column" lg style={{ maxWidth: "35em" }}>
            <Grid item>
              <Typography variant="h4" gutterBottom align={matchesMd ? "center" : undefined}>
                History
              </Typography>
            </Grid>

            <Grid item>
          <Typography align={matchesMd ? "center" : undefined} variant="body2" paragraph style={{ fontWeight: 700, fontStyle: "italic" }}>
            We're the dreamers and doers
          </Typography>
          <Typography align={matchesMd ? "center" : undefined} variant="body2" paragraph>
          Our journey began in 2015, and since then we have participated in many diverse projects in the domain of technology.
          </Typography>
          <Typography align={matchesMd ? "center" : undefined} variant="body2" paragraph>
            It all started with one vision: To make your dreams come true with our IT development services. We have created applications for various domains, such as transportation, food delivery, education, and retail. Some of our notable clients include Uber, Foodpanda, Inventory Management System, and POS System.
          </Typography>
          <Typography align={matchesMd ? "center" : undefined} variant="body2" paragraph>
            We work on every technology, from front-end to back-end, from native to hybrid, from cloud to local. We have a team of 20 talented and experienced employees, including mobile developers, website developers, content writers, and graphic designers. We are passionate about creating innovative and user-friendly solutions that meet the needs and expectations of our clients.
          </Typography>
          <Typography align={matchesMd ? "center" : undefined} variant="body2" paragraph>
            We are always looking for new opportunities and challenges to expand our horizons and improve our services. Dream X Work is more than just an IT development company. We are a team of dreamers and doers who work hard to make your dreams come true.
          </Typography>
        </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container justifyContent="center" lg>
            <img src={history} alt="quill pen sitting on top of book" style={{ maxHeight: matchesMd ? 200 : "20em" }} />
          </Grid>
        </Grid>
      </Grid>
<div>

      <Grid item container direction="column" justifyContent="center" alignItems="center" className={classes.rowContainer} style={{ marginTop: "3em" }}>
  {/* Contact Information */}
  <Typography variant="h4" gutterBottom align={matchesMd ? "center" : undefined}>

    For Contact Information
  </Typography>
</Grid>
      <Grid item container direction="column" justifyContent="center" alignItems="center" className={classes.rowContainer} style={{ marginBottom: "3em" }}>
  {/* Contact Information */}
  <Typography variant="h4" align="center" className={classes.missionStatement}
  >
    Phone: <a href="tel:+923466392886">+923466392886</a>
    <br />
    Email: <a href="mailto:awaisyasin154@gmail.com">awaisyasin154@gmail.com</a>
  </Typography>
</Grid>
</div>
      {/**end history block */}
      {/**team block */}
      {/* <Grid item container direction="column" alignItems="center" style={{ marginBottom: "15em" }} className={classes.rowContainer}>
        <Grid item>
          <Typography align="center" variant="h4" gutterBottom>
            Team
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" paragraph align="center">
            Hafiz Awais Yasin, Founder
          </Typography>
          <Typography variant="body2" paragraph align="center">
            I start coding when I was 15 years old.
          </Typography>
        </Grid>
        <Grid item>
          <Avatar alt="founder" src={profile} className={classes.avatar} />
        </Grid>
        <Grid item container justifyContent={matchesMd ? "center" : undefined}>
          <Grid
            sx={{ display: { sm: "block", md: "block", mh: "block", lg: "none", xs: "block" } }}
            item
            lg
            style={{ maxWidth: "45em", padding: "1.25em" }}
          >
            <Typography variant="body2" align="center" paragraph>
              I taught myself basic coding from a library book in third grade, and ever since then my passion has solely been set on learning -
              learning about computers, learning mathematics and philosophy, studying design, always just learning.{" "}
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Now I'm ready to apply everything I've learned, and to help others with the intution I have developed.
            </Typography>
          </Grid>
          <Grid item container direction="column" lg alignItems={matchesMd ? "center" : undefined} style={{ marginBottom: matchesMd ? "2.5em" : 0 }}>
            <Grid item>
              <img src={yearbook} alt="yearbook page abput founder" style={{ maxWidth: matchesMd ? 300 : undefined }} />
            </Grid>
            <Grid item>
              <Typography variant="caption">a page from my Sophomore yearbook</Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{ display: { sm: "none", md: "none", mh: "none", lg: "block", xs: "none" } }}
            item
            lg
            style={{ maxWidth: "45em", padding: "1.25em" }}
          >
            <Typography variant="body2" align="center" paragraph>
              I taught myself basic coding from a library book in third grade, and ever since then my passion has solely been set on learning -
              learning about computers, learning mathematics and philosophy, studying design, always just learning.{" "}
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Now I'm ready to apply everything I've learned, and to help others with the intution I have developed.
            </Typography>
          </Grid>
          <Grid item container direction="column" lg alignItems={matchesMd ? "center" : "flex-end"}>
            <Grid item>
              <img src={puppy} alt="green parrot" style={{ maxWidth: matchesMd ? 300 : undefined }} />
            </Grid>
            <Grid item>
              <Typography variant="caption">my parrot, Subfamily Arinae</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      {/**end team block */}
      <Grid item>
        <CallToAction setValue={props.setValue} />
      </Grid>
    </Grid>
  );
};

export default About;
